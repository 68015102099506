
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AppProvider } from './AppContext';
import React from 'react';

import './App.css';

import ReactGA from 'react-ga';
  
import StartScreen from './components/portrait/StartScreen';
import CharacterSelection from './components/portrait/CharacterSelection';
import SettingSelection from './components/portrait/SettingSelection';
import VillainSelection from './components/portrait/VillainSelection';
import HelperSelection from './components/portrait/HelperSelection';
import InterestsSelection from './components/portrait/InterestsSelection';
import Feedback from './components/portrait/Feedback';
import Story from './components/portrait/Story';
import WordStory from './components/portrait/WordStory';


const TRACKING_ID = "G-9PSKZ9Q7NE"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }


  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>Something went wrong.</p>;
    }

    return this.props.children;
  }

}

function MyFallbackComponent({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<StartScreen />} />
            <Route path="/character" element={<CharacterSelection />} />
            <Route path="/setting" element={<SettingSelection />} />
            <Route path="/villain" element={<VillainSelection />} />
            <Route path="/helper" element={<HelperSelection />} />
            <Route path="/interests" element={<InterestsSelection />} />
            <Route path="/story" element={<Story />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/word-story" element={<WordStory />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </ErrorBoundary>
  );
}

export default App;
